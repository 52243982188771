
<template>
    <div>
        <CCard class="p-2">
            <form >	
                <CCardHeader>
                    <CRow><CButton size="sm" class="btn btn-link back-btn"
				@click="backToTable()"><i class="fa fa-arrow-left"></i> Back</CButton>
		    	</CRow>
					
				</CCardHeader>
                <CCardBody class="card-form">
                 <CCard class="pl-3 pr-3">
					<CRow class="mt-3">
						<CCol lg="12">
							<strong class="p-1 mb-2 bg-secondary text-dark" 
									style="font-size:12px; border-radius: 5px;"> Customer Information
							</strong>
						</CCol>
						
					</CRow><br>
                    <CRow class="mb-2">
	        		<CCol lg="3">
	        			Customer Name
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600"> {{dataParams.customer_name}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Contact Number
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.contact_no}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Email Address
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.email}} </span>
	        		</CCol>
	        	</CRow>
				<CRow class="mb-2">
	        		<CCol lg="3">
	        			Address 1
	        		</CCol>
	        		<CCol lg="9">
	        		<span style="font-weight:600"> 	{{dataParams.address_a ? dataParams.address_a : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Address 2
	        		</CCol>
	        		<CCol lg="9">
	        		<span style="font-weight:600"> 	{{dataParams.address_b ? dataParams.address_b : "-"}} </span>
	        		</CCol>
	        	</CRow>
				<br>
				<CRow class="mb-2">
						<CCol lg="12">
							<strong class="p-1 mb-2 bg-secondary text-dark" 
									style="font-size:12px; border-radius: 5px;"> Other Information
							</strong>
						</CCol>
						
				</CRow><br>
				<CRow class="mb-2">
	        		<CCol lg="3">
	        			Depot
	        		</CCol>
	        		<CCol lg="9">
	        		<span style="font-weight:600"> 	{{dataParams.depot == null ? "N/A" : dataParams.depot.setting_name}} </span>
	        		</CCol>
	        	</CRow>
	        	
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Contact Person
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.contact_person ? dataParams.contact_person : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			TIN
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.tin ? dataParams.tin : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Account Code
	        		</CCol>
	        		<CCol lg="9">
	        		<span style="font-weight:600"> 	{{dataParams.account_code  ? dataParams.account_code : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Rate Type
	        		</CCol>
	        		<CCol lg="9">
	        			<span style="font-weight:600">  {{dataParams.rate_type ? dataParams.rate_type : "-"}} </span>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="3">
	        			Status
	        		</CCol>
	        		<CCol lg="9" class="text-uppercase">
        
                     <span :class="dataParams.status === 'active' ? 'bg-success' : 'bg-danger'"
                            style="font-weight:600; padding: 3px; width: 100px; border-radius:5px;">
                            {{ dataParams.status }}
                    </span>

	        		</CCol>
	        	</CRow> 
                </CCard>
               
             </CCardBody>
           

          
            </form>
        </CCard>
    </div>
</template>

<script>
import config from '../../config.js';
import axios from '../../axios';


export default {
	mounted(){  
		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

	},
	data(){
		return{
			config,
			options:'',
			title:'',
			isLoading: false,
			formModal: false,
			count: 0,
			depotName: "",
			editMode: false,
			selectedRow: {},
			showCollapse: false,
            dataParams: {}

			
		}
	},
	watch: {
		
	},
	name: 'Tables',
	components: {},
	methods: { 
		 backToTable(){
	    	this.$router.push('/data/customer')
	    },
		getData(){

			this.$showLoading(true)
			axios.get(config.api_path+"/customer/"+this.$route.params.id)
			.then(response=>{
				this.$showLoading(false)
				this.dataParams = response.data.data
			})
			.catch(err => {
			this.$showLoading(false)
		}) 
    },
		

 	}
}
</script>
